import React from 'react';
import { graphql } from 'gatsby';
import {
  TRANSPARENT,
} from '@constants';
import {
  Layout, BasicDarkHero,
} from '@molecules';
import {
  TableOfContents, Issue, IssueIntroduction, IssueConclusion,
} from '@organisms';
import { setSearchParams, getKeyValue } from '@utils';

const ContentIssuesListingTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, settings, languageLabels,
  } = data;

  const { locale } = pageContext;
  const { siteTitle } = settings;
  const { social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    metaTitle, metaDescription, metaImage, path, pageContent, disableAlertBar,
    enLanguagePageLinks, pageInOtherLanguages, disableDonationBar,
  } = page || {};
  const {
    title: pageTitle, introduction, listingSections, conclusion,
    tocTitle, returnTopText, tableOfContentsStickyTitle,
  } = pageContent;
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};

  const searchParams = setSearchParams(location);

  return (
    <Layout
      location={location}
      metaDescription={metaDescription && metaDescription.metaDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${path}`}
      metaTitle={`${metaTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      locale={locale}
      navTheme={TRANSPARENT}
      settings={settings}
      navAnimate
      pageInOtherLanguages={pageInOtherLanguages}
      enLanguagePageLinks={enLanguagePageLinks}
      languageLabels={languageLabels}
      disableDonationBar={disableDonationBar}
      disableAlertBar={disableAlertBar}
      searchParams={searchParams}
    >
      <BasicDarkHero
        title={pageTitle}
      />
      {introduction && introduction.raw && (
        <IssueIntroduction introduction={introduction} />
      )}
      {listingSections && (
        <React.Fragment>
          <TableOfContents
            issues={listingSections}
            location={location}
            tocTitle={tocTitle}
            tocStickyTitle={tableOfContentsStickyTitle}
          />
          <React.Fragment>
            {listingSections.map((issue, i) => (
              <Issue
                key={`${issue.id}--issue`}
                {...issue}
                returnTop={returnTopText}
                last={i + 1 === listingSections.length}
              />
            ))}
          </React.Fragment>
        </React.Fragment>
      )}
      {conclusion && conclusion.raw && (
        <IssueConclusion conclusion={conclusion} />
      )}
    </Layout>
  );
};

export default ContentIssuesListingTemplate;

export const contentIssuesListingPageQuery = graphql`
  query ContentIssuesListingPageBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(path: { eq: $slug }, language: { eq: $locale }) {
      ...PageMetadata
      pageContent {
        ... on ContentfulPageContentContentListing {
          title
          id
          introduction {
            raw
            references {
              ... on ContentfulPage {
                contentful_id
                path
              }
            }
          }
          conclusion {
            raw
            references {
              ... on ContentfulPage {
                contentful_id
                path
              }
            }
          }
          listingType
          returnTopText
          tocTitle
          tableOfContentsStickyTitle
          listingSections {
            ... on ContentfulComponentIssue {
              id
              longTitle
              shortTitle
              description {
                description
              }
              summary {
                raw
                references {
                  ... on ContentfulPage {
                    contentful_id
                    path
                  }
                }
              }
              fullPlanLink {
                label
                internalPageLink {
                  path
                }
              }
            }
          }
        }
      }
    }
    settings: contentfulGlobalSiteSettings(language: { eq: $locale }) {
      ...GlobalSiteSettings
    }
    languageLabels: contentfulGlobalSiteSettings(language: { eq: "en-US" }) {
      ...LanguageLabels
    }
  }
`;
